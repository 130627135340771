<template>
<div>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader class="text-center bg-dark text-white">
          <b>{{ $t("label.initialInventory") }}</b>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="12" class="d-flex justify-content-end">
              <CButton
                shape="square"
                color="add"
                v-c-tooltip="{ placement: 'letf', content: $t('label.add') }"
                class="d-flex align-items-center"
                @click="toggleModal"
              >
                <CIcon name="cil-playlist-add" /><span class="ml-1">{{
                  $t("label.nuevo")
                }}</span>
              </CButton>
            </CCol>
            <CCol sm="12">
              <dataTableExtended
                class="align-center-row-datatable"
                hover
                sorter
                column-filter
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :items="formatedItems"
                :fields="fields"
                :loading="loading"
                :noItemsView="tableText.noItemsViewText"
                :items-per-page="5"
                :active-page="activePage"
                pagination
              >
                <template #loading>
                  <loading />
                </template>
                <template #Status="{ item }">
                  <td class="text-center align-middle">
                    <div>
                      <CBadge :color="getBadge(item.Status)">
                        {{ $t("label." + item.Status) }}
                      </CBadge>
                    </div>
                  </td>
                </template>

                <template #Details="{ item }">
                  <td class="center-cell">
                    <CButtonGroup>
                      <CButton
                        shape="square"
                        color="edit"
                        size="sm"
                        v-c-tooltip="{
                          content: $t('label.edit') + ' ' + '',
                          placement: 'top-end',
                        }"
                        @click="toggleModal(true, item)"
                      >
                        <CIcon name="pencil" />
                      </CButton>
                    </CButtonGroup>
                  </td>
                </template>
              </dataTableExtended>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>

    <inventory-modal
      :modal.sync="modal"
      :inventoryId="inventory"
      :edit="edit"
      @submited="handleSubmit"
    />
  </CRow>
</div>
</template>

<script>
import { mapState } from "vuex";
import { DateFormater } from "@/_helpers/funciones";
import { formatMilDecimal } from '@/_validations/validacionEspeciales';
import General from "@/_mixins/general";
import InventoryModal from "./inventory-modal";


function fields() {
  return [
    { key: "Nro", label: "#", _style: "width:1%;", _classes: "text-center", filter: false,},
    { key: "BL", label: 'BL',  },
    {
      key: "ClientName",
      label: this.$t("label.client"),
    },
    {
      key: "WeightTON",
      label: this.$t("label.weight")+'(TON)',
    },
    {
      key: "WeightKG",
      label: this.$t("label.weight")+'(KG)',
    },
    { key: "Usuario", label: this.$t("label.user"), },
    { key: "Fecha", label: this.$t("label.date"), _classes: "text-center" },
    { key: "Status", label: this.$t("label.status"), _classes: "text-center" },
    {
      key: "Details",
      label: "",
      sorter: false,
      filter: false,
      _styles: "width: 1%;",
    },
  ];
}
function cellTableClasses() {
  return {
    Nro: "align-middle",
    BL: "align-middle",
    ClientName: "align-middle",
    WeightTON: "align-middle",
    WeightKG: "align-middle",
    Usuario: "align-middle",
    Fecha: "align-middle",
    Status: "align-middle",
  };
}

//DATA
function data() {
  return {
    items: [],
    loading: false,
    modal: false,
    edit: false,
    inventory: '',
    activePage: 1,
  };
}

//METHOD
function getInventoryList() {
  this.loading = true;

  this.$http
    .get("InventoryBillOfLadingYard-list", { CompanyBranchId: this.getBranchId })
    .then((response) => {
      this.items = response.data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.loading = false;
    });
}
function toggleModal(edit = false, item = null) {
  if (edit && item) {
    this.edit = edit;
    this.inventory = item.BillOfLadingYardId;
  }

  this.modal = true;
}
function handleSubmit(){
  this.getInventoryList();
}

// COMPUTED
function formatedItems() {
  return this.items.map((inventory) =>
    Object.assign({}, inventory, {
      Nro: inventory.Nro,
      BL: inventory.NroBl,
      ClientName: inventory.ClientName,
      WeightTON: formatMilDecimal(parseFloat(inventory.TotalWeigthJson[1].Value).toFixed(2)),
      WeightKG: formatMilDecimal(parseFloat(inventory.TotalWeigthJson[0].Value).toFixed(2)),
      Usuario: inventory.TransaLogin ? inventory.TransaLogin : "N/A",
      Fecha: inventory.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(inventory.TransaRegDate)
        : "N/A",
      Status: inventory.Status,
      _classes: inventory.Status === "INACTIVO" ? "table-danger" : "",
      _cellClasses: this.cellTableClasses,
    })
  );
}
function getBranchId() {
  return this.branch ? this.branch.CompanyBranchId : "";
}

export default {
  name: "inventory-list",
  mixins: [General],
  data,
  components: {
    InventoryModal,
  },
  methods: {
    getInventoryList,
    toggleModal,
    handleSubmit,
  },
  watch: {
    modal: function (val) {
      if (!val) {
        this.edit = false;
        this.inventory = '';
      }
    },
  },
  computed: {
    fields,
    formatedItems,
    cellTableClasses,
    getBranchId,
    ...mapState({
      branch: (state) => state.auth.branch,
      user: (state) => state.auth.user,
    }),
  },
  created() {
    this.getInventoryList();
  },
  mounted() {},
};
</script>
<style scoped>
.upload-progress {
  width: 100%;
}
</style>
